import {Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {isEmpty, update, keys, concat, get} from "lodash";
import {
    getMerchantAuthorizedByAuthCode,
    submitMerchantToOnboard
} from "../../api/AuthorizeMerchantByCode";
import {useHistory} from 'react-router-dom';
import reckonPayments from "../../images/reckon-logo.png";
import Loader from "../Loader";
import {LandingErrorPage} from "../LandingPageError";
import {ContactAndGeneralDetailsSection} from "./sections/ContactAndGeneralDetailsSection";
import {BusinessAndBankDetailsSection} from "./sections/BusinessAndBankDetailsSection";
import {
    CLIENT_CODE, MERCHANT_DATA_PATHS,
    ONBOARDING_PAGE_SECTION_INDEXES
} from "../../constants/CommonConstants";
import {
    containsValidationErrors,
    getFormattedValueForInput,
    getInputValues,
    getTruncateString,
    getValidationErrorMessage,
    handleCustomInputChange,
    removeNumbersAndSpecialCharacters,
    removeSpecialCharacters,
    updateMerchantDetailsBasedOnFiledValues
} from "./utils/MerchantDataInputUtils";
import {splitName} from "../../util/CommonFunctions";

export function OnboadingPage(props) {

    const [merchantDetails, setMerchantDetails] = useState({});
    const [accessToken, setAccessToken] = useState("");
    const [bookId, setBookId] = useState("");
    const [isStatementUploaded, setStatementUploaded] = useState(false);
    const [fieldValues, setFieldValues] = useState(getInputValues(merchantDetails));

    let queryParams = props.location.search.substr(1);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [isError, setIsError] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    useEffect(() => {
        const inputFieldValues = getInputValues(merchantDetails);
        setFieldValues({...inputFieldValues})
    }, [merchantDetails])

    useEffect(() => {
        async function LoadPageData() {
            try {
                await authorizeMerchantTokenDetailsByCode(setMerchantDetails, queryParams, setAccessToken, setBookId);
            } catch (e) {
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        }

        LoadPageData();
    }, [])

    const onFinishClick = async (merchantDetails, accessToken, setIsLoading, history) => {
        if (containsValidationErrors(currentPageIndex, fieldValues, setFieldValues)) {
            return;
        }
        const updatedMerchantDetails = updateMerchantDetailsBasedOnFiledValues(merchantDetails, fieldValues);
        try {
            const payload = {
                ...updatedMerchantDetails,
                financials: {
                    ...updatedMerchantDetails.financials,
                    charge: {
                        ...updatedMerchantDetails.financials.settlement
                    }
                },
                statementReference: {
                    clientCode: CLIENT_CODE,
                    externalId: bookId
                },
                additionalInformation: {
                    partnerTag: 'reckon'
                }
            }
            setIsLoading(true);
            submitMerchantToOnboard(payload, accessToken).then(result => {
                history.push("/success");
            }).catch((error) => {
                if (error.response.status === 400) history.push("/landing-error?q=error_code=400");
            });
        } catch (e) {
            setIsLoading(false);
        }
    }

    const onNextClick = () => {
        if (containsValidationErrors(currentPageIndex, fieldValues, setFieldValues)) {
            return;
        }
        const lastIndex = Object.keys(ONBOARDING_PAGE_SECTION_INDEXES).length - 1;
        if (currentPageIndex < lastIndex) {
            setCurrentPageIndex(currentPageIndex + 1)
        }
    }

    const onPreviousClick = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1)
        }
    }

    const onInputChange = (section, path, value) => {
        if (isEmpty(path)) {
            return;
        }
        const formattedValue = getFormattedValueForInput(path, value)
        const validationMessage = getValidationErrorMessage(path, formattedValue);
        fieldValues[section][path] = {
            ...fieldValues[section][path],
            value: formattedValue,
            validationMessage
        }
        if (fieldValues[section][path].isCustom && isEmpty(validationMessage)) {
            handleCustomInputChange(section, path, formattedValue, onInputChange)
        }
        setFieldValues({...fieldValues})
    }

    if (isLoading) {
        return <Loader/>
    } else if (isError) {
        return <LandingErrorPage/>
    }

    return (
        <div className="p-3">
            <div className='d-flex justify-content-center mb-5'>
                <img src={reckonPayments} className="novatti-logo"/>
            </div>
            <div className="center-form-container">
                <Grid container spacing={4} className="m-2">
                    {currentPageIndex === ONBOARDING_PAGE_SECTION_INDEXES.CONTACT_AND_GENERAL_DETAILS_SECTION_INDEX
                        && <ContactAndGeneralDetailsSection
                            onInputChange={onInputChange}
                            inputValues={fieldValues[ONBOARDING_PAGE_SECTION_INDEXES.CONTACT_AND_GENERAL_DETAILS_SECTION_INDEX]}
                        />
                    }
                    {currentPageIndex === ONBOARDING_PAGE_SECTION_INDEXES.BUSINESS_AND_BANK_DETAILS_SECTION_INDEX
                        && <BusinessAndBankDetailsSection
                            onInputChange={onInputChange}
                            inputValues={fieldValues[ONBOARDING_PAGE_SECTION_INDEXES.BUSINESS_AND_BANK_DETAILS_SECTION_INDEX]}
                            bookId={bookId}
                            accessToken={accessToken}
                            setStatementUploaded={setStatementUploaded}
                        />
                    }
                    <Grid item md={12} className="w-100">
                        <div className='w-100 mb-4'>
                            <button className="onboarding-button onboarding-button-left" onClick={e => submitCancel(history)}>Cancel</button>
                            {currentPageIndex === keys(ONBOARDING_PAGE_SECTION_INDEXES).length - 1
                                // && isStatementUploaded
                                && <button className="onboarding-button onboarding-button-right"
                                           onClick={e => onFinishClick(merchantDetails, accessToken, setIsLoading, history)}>Finish</button>
                            }
                            {currentPageIndex < keys(ONBOARDING_PAGE_SECTION_INDEXES).length - 1
                                && <button className="onboarding-button onboarding-button-right"
                                           onClick={e => onNextClick()}>Next</button>
                            }
                            {currentPageIndex > 0
                                && <button className="onboarding-button onboarding-button-right"
                                           onClick={e => onPreviousClick()}>Previous</button>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}

async function authorizeMerchantTokenDetailsByCode(setMerchantDetails, queryParams, setAccessToken, setBookId) {

    const res = await getMerchantAuthorizedByAuthCode(queryParams);
    let merchantDetails = res.data.data.merchantDetails;
    if (!merchantDetails.contact.lastName) {
        const nameSplit = splitName(merchantDetails.contact.firstName);
        merchantDetails.contact.firstName = nameSplit.fName;
        merchantDetails.contact.lastName = nameSplit.lName;
    }
    let bookId = res.data.data.bookId;
    merchantDetails.profile.externalMerchantId = bookId;
    merchantDetails.tradingEntity.country = "AUS";
    setMerchantDetails(formatMerchantDetails(merchantDetails));
    setBookId(bookId);
    setAccessToken(res.data.data.accessToken);
}

export const formatMerchantDetails = (merchantDetails) => {
    merchantDetails.legalEntity.registeredName = formatField(merchantDetails.legalEntity.registeredName, 120)
    merchantDetails.tradingEntity.name = formatField(merchantDetails.tradingEntity.name, 30)
    merchantDetails.contact.firstName = formatFieldWithRemovingNumbers(merchantDetails.contact.firstName, 30)
    merchantDetails.contact.lastName = formatFieldWithRemovingNumbers(merchantDetails.contact.lastName, 30)
    merchantDetails.financials.settlement.accountName = formatFieldWithRemovingNumbers(merchantDetails.financials.settlement.accountName, 40)
    merchantDetails.tradingEntity.addressLine1 = formatField(merchantDetails.tradingEntity.addressLine1, 35)
    merchantDetails.tradingEntity.addressLine2 = formatField(merchantDetails.tradingEntity.addressLine2, 35)
    merchantDetails.tradingEntity.locality = formatFieldWithRemovingNumbers(merchantDetails.tradingEntity.locality, 35)
    merchantDetails.contact.email = truncateField(merchantDetails.contact.email, 60)

    return merchantDetails;
}

const truncateField = (inputString, maxLength) => {
    if (isEmpty(inputString)) {
        return inputString;
    }
    return getTruncateString(inputString, 60)
}

const formatField = (inputString, maxLength) => {
    if (isEmpty(inputString)) {
        return inputString;
    }

    return removeSpecialCharacters(getTruncateString(inputString, maxLength));
}

const formatFieldWithRemovingNumbers = (inputString, maxLength) => {
    if (isEmpty(inputString)) {
        return inputString;
    }

    return removeNumbersAndSpecialCharacters(getTruncateString(inputString, maxLength));
}


function submitCancel(history) {
    const openedWindow = window.open("about:blank", "_self");
    openedWindow.close();
}