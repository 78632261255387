import React from "react";
import {InputAdornment, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import {isEmpty} from "lodash";

export const OnboardingDropDownInputField =  ({ label, placeholder, value, validationMessage, isRequired, disabled, onInputChangeHandler, path, defaultValue, menuItemValues })  => {
    return (
        <div className='mb-2'>
            <div className='mb-1' id="label">
                <p className='float-left col-form-label' style={{fontSize: "1rem"}}>{label}{isRequired ? '*': ''}</p>
                {!isEmpty(validationMessage) && <p className='float-right col-form-label' style={{fontSize: "1rem", color: "red"}}>{validationMessage}</p>}
            </div>
            <Select
                className='w-100'
                labelId="label"
                id="select" value={defaultValue.value} onChange={e => onInputChangeHandler(e.target.value)}>
                {
                    menuItemValues.map(menuItem => {
                        return <MenuItem value={menuItem.key}>{menuItem.value}</MenuItem>
                    })
                }
            </Select>
        </div>
    );
}