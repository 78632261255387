import React from "react";
import {InputAdornment, OutlinedInput} from "@material-ui/core";
import {isEmpty} from "lodash";

export const OnboardingInputField =  ({ label, placeholder, value, validationMessage, isRequired, disabled, onInputChangeHandler, path })  => {
    return (
        <div className='mb-2'>
            <div className='mb-1'>
                <p className='float-left col-form-label' style={{fontSize: "1rem"}}>{label}{isRequired ? '*': ''}</p>
                {!isEmpty(validationMessage) && <p className='float-right col-form-label' style={{fontSize: "1rem", color: "red"}}>{validationMessage}</p>}
            </div>
            <OutlinedInput
                type="text"
                className='w-100'
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                style={{height: "2.5rem"}}
                onChange={e => onInputChangeHandler(e.target.value)}
                startAdornment={
                    <InputAdornment position="center">
                    </InputAdornment>
                }
            />
        </div>
    );
}