import React, {useState} from 'react';
import {Grid} from "@material-ui/core";

import AliPayLogo from "../../../images/alipay_logo.png";
import AmexLogo from "../../../images/amex_logo.png";
import DirectDebitLogo from "../../../images/direct_debit_logo.png";
import MasterCardLogo from "../../../images/master_card_logo.png";
import PayXCryptoLogo from "../../../images/pay_crypto_logo.png";
import VisaLogo from "../../../images/visa_logo.png";
import WeChatLogo from "../../../images/we_chat_pay_logo.png";

export const PaymentFeesComponent = () => {

    const [fees] = useState([
        {
            "iconList": [
                {"iconSource": VisaLogo},
                {"iconSource": MasterCardLogo}
            ],
            "descriptionList": [
                {
                    "description": "Domestic Cards",
                    "fee": "1.5%"
                },
                {
                    "description": "International Cards",
                    "fee": "3%"
                },
                {
                    "description": "Standard Chargeback Fee",
                    "fee": "$35"
                }
            ]
        },
        {
            "iconList": [
                {"iconSource": AliPayLogo},
                {"iconSource": WeChatLogo}
            ],
            "descriptionList": [
                {
                    "description": "Coming soon"
                }
            ]
        },
        {
            "iconList": [
                {"iconSource": DirectDebitLogo}
            ],
            "descriptionList": [
                {
                    "description": "Coming soon"
                }
            ]
        },
        {
            "iconList": [
                {"iconSource": PayXCryptoLogo, "width": '75px'}
            ],
            "descriptionList": [
                {
                    "description": "Coming soon"
                }
            ]
        },
        {
            "iconList": [
                {"iconSource": AmexLogo}
            ],
            "descriptionList": [
                {
                    "description": "Coming soon"
                }
            ]
        },
    ]);

    return (
        <div className="form-box-wrapper">
            {fees.map(feeEntry => {
                return (
                    <Grid container className="fees-row" justifyContent="flex-start" alignItems="center">
                        <Grid item md={5} xs={4}>
                            {feeEntry.iconList.map((icon) => <img className="m-1" style={{width: icon.width ? icon.width : '45px'}} src={icon.iconSource}/>)}
                        </Grid>
                        <Grid item md={7} xs={8} className="mb-0">
                            {feeEntry.descriptionList.map(descriptionEntry =>
                                <Grid container className="mb-0">
                                    <Grid item md={10} xs={10} className="mb-0"><p
                                        className="payment-fee-text mb-1">{descriptionEntry.description}</p></Grid>
                                    <Grid item md={2} xs={2} className="mb-0"><p
                                        className="payment-fee-text mb-1">{descriptionEntry.fee}</p></Grid>
                                </Grid>)
                            }
                        </Grid>
                    </Grid>
                );
            })}
            <p className="payment-fee-text mb-1 mt-3 text-center">Note: Price is inclusive of GST**, No monthly or
                annual setup fees apply.</p>
        </div>
    );
}