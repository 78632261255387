import React, {useState} from 'react';
import {FormGroup, Grid} from "@material-ui/core";
import {OnboardingInputField} from "../components/OnboardingInputField";
import {OnboardingInputDateField} from "../components/OnboardingInputDateField";
import {MERCHANT_DATA_PATHS, ONBOARDING_PAGE_SECTION_INDEXES} from "../../../constants/CommonConstants";
import {PaymentFeesComponent} from "./PaymentFeesComponent";
import {splitName} from "../../../util/CommonFunctions";
import {OnboardingHiddenInputField} from "../components/OnboardingHiddenInputField";
import {OnboardingDropDownInputField} from "../components/OnboardingDropDownInputField";
import {OnboardingInputFieldWithSymbolledLabel} from "../components/OnboardingInputFieldWithSymbolledLabel";

export const ContactAndGeneralDetailsSection = ({onInputChange, inputValues}) => {

    const section = ONBOARDING_PAGE_SECTION_INDEXES.CONTACT_AND_GENERAL_DETAILS_SECTION_INDEX;

    return (
        <React.Fragment>
            <Grid item md={6} xs={12} className='pl-4 pr-4'>
                <div className="d-flex mt-4 mb-4">
                    <p style={{fontSize: "1.4rem", marginBottom: "0"}}>General Information</p>
                </div>
                <div className="form-box-wrapper">
                    <FormGroup>
                        <OnboardingInputField
                            label="Legal Entity Name"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_LEGAL_ENTITY_NAME]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_LEGAL_ENTITY_NAME, value)
                            }
                        />
                        <OnboardingInputField
                            label="Trading (business) Name"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_NAME]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_NAME, value)
                            }
                        />
                        <OnboardingInputField
                            label="ABN/WPN"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_PROFILE_ABN]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_PROFILE_ABN, value)
                            }
                        />
                        <OnboardingHiddenInputField
                            label="External Merchant ID"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_PROFILE_EXTERNAL_MERCHANT_ID]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_PROFILE_EXTERNAL_MERCHANT_ID, value)
                            }
                        />
                        <OnboardingInputField
                            label="ACN"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_PROFILE_ACN]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_PROFILE_ACN, value)
                            }
                        />
                        <OnboardingInputField
                            label="Industry"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_PROFILE_INDUSTRY]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_PROFILE_INDUSTRY, value)
                            }
                        />
                    </FormGroup>
                </div>
            </Grid>
            <Grid item md={6} xs={12} className="desktop-only-left-border pl-4 pr-4">
                <Grid container>
                    <Grid item md={12} className="w-100">
                        <div className="d-flex mt-4 mb-4">
                            <p style={{fontSize: "1.4rem", marginBottom: "0"}}>Contact Details</p>
                        </div>
                        <div className="form-box-wrapper">
                            <FormGroup>
                                <OnboardingInputField
                                    label="First Name"
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_FIRSTNAME]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_FIRSTNAME, value)
                                    }
                                />
                                <OnboardingInputField
                                    label="Last Name"
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_LASTNAME]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_LASTNAME, value)
                                    }
                                />
                                <OnboardingInputField
                                    label="Email"
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_EMAIL]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_EMAIL, value)
                                    }

                                />
                                <OnboardingInputField
                                    label="Phone Number"
                                    placeholder={"0XXXXXXXXX"}
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER, value)
                                    }
                                />
                                <OnboardingInputField
                                    label="Driver’s License Number"
                                    placeholder={""}
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE, value)
                                    }
                                />
                                <OnboardingDropDownInputField
                                    label="Driver’s License State"
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_STATE]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_STATE, value)
                                    }
                                    defaultValue={inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_STATE]}
                                    menuItemValues={[
                                        {key: 'ACT', value: 'Australian Capital Territory'},
                                        {key: 'NSW', value: 'New South Wales'},
                                        {key: 'NT', value: 'Northern Territory'},
                                        {key: 'QLD', value: 'Queensland'},
                                        {key: 'SA', value: 'South Australia'},
                                        {key: 'TAS', value: 'Tasmania'},
                                        {key: 'VIC', value: 'Victoria'},
                                        {key: 'WA', value: 'Western Australia'}

                                    ]}
                                />
                                <OnboardingInputFieldWithSymbolledLabel
                                    label="Driver’s License Card Number"
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_CARD_NUMBER]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_CARD_NUMBER, value)
                                    }
                                />
                                <OnboardingInputDateField
                                    label="Date of Birth"
                                    placeholder={"1994-07-30"}
                                    {...inputValues[MERCHANT_DATA_PATHS.PATH_DATE_OF_BIRTH]}
                                    onInputChangeHandler={
                                        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_DATE_OF_BIRTH, value)
                                    }
                                />
                            </FormGroup>
                        </div>
                    </Grid>
                    <Grid item md={12}>
                        <div className="d-flex mt-4 mb-4">
                            <p style={{fontSize: "1.4rem", marginBottom: "0"}}>Payment Services & Fees</p>
                        </div>
                        <PaymentFeesComponent />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}