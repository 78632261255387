export function splitName(firstName) {
    if (firstName && firstName.length > 0) {
        const splitName = firstName.trim().split(/\s+/);
        if (splitName.length > 1) {
            const lName = splitName.length > 1 ? splitName[splitName.length - 1]: "";
            const fName = splitName.slice(0, -1).join(" ");
            return {'fName': fName, 'lName': lName};
        }
        return {'fName': firstName, 'lName': ''};
    }
    return {'fName': firstName, 'lName': ''};
}