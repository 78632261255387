import React from "react";
import {Box, InputAdornment, Modal, OutlinedInput, Typography} from "@material-ui/core";
import {isEmpty} from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const OnboardingInputFieldWithSymbolledLabel =  ({ label, placeholder, value, validationMessage, isRequired, disabled, onInputChangeHandler, path })  => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const buttonStyle = {
        customizedButton: {
            position: 'absolute',
            top: 0,
            right: 0
        }
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const iframeStyle = {
        position: "absolute",
        display:'flex',
        width: '90%',
        height: '80%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }

    return (
        <div>
            <div className='mb-2'>
                <div className='mb-1'>
                    <p className='float-left col-form-label' style={{fontSize: "1rem"}}>{label}{isRequired ? '*': ''}</p>
                    <FontAwesomeIcon icon={faCircleQuestion} onClick={handleOpen}/>
                    {!isEmpty(validationMessage) && <p className='float-right col-form-label' style={{fontSize: "1rem", color: "red"}}>{validationMessage}</p>}
                </div>
                <OutlinedInput
                    type="text"
                    className='w-100'
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    style={{height: "2.5rem"}}
                    onChange={e => onInputChangeHandler(e.target.value)}
                    startAdornment={
                        <InputAdornment position="center">
                        </InputAdornment>
                    }
                />
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <IconButton style={buttonStyle.customizedButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        License card number
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Refer to the following document.
                    </Typography>
                    <div>
                        <iframe src="/licence.pdf" frameBorder="0" title="License Number" style={iframeStyle}></iframe>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}