import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import reckonPayments from "../images/reckon-logo.png";

export default function Loader() {
    return (
        <div className="App loader">
            <div className="d-flex justify-content-center mt-4">
                <p style={{fontSize: "1.1rem", fontWeight: "600", marginBottom: "0"}}>WELCOME TO</p>
            </div>
            <div className='d-flex justify-content-center mb-5'>
                <img src={reckonPayments} className="novatti-logo"/>
            </div>
            <div className="d-flex justify-content-center mt-5 mb-5">
                <h3><FontAwesomeIcon icon={faSpinner} className="fa-pulse" color="Grey" size="4x"   /></h3>
            </div>
        </div>
    );
}