import './App.css';
import React, {useEffect} from "react";
import { BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {LandingPage} from "./views/OnBoardLandingPage";
import {LoginCallBack} from "./views/LoginCallBack";
import {SubmitSuccessPage} from "./views/SubmitSuccess";
import {LandingErrorPage} from "./views/LandingPageError";
import {OnboadingPage} from "./views/onboarding/OnboardingPage";

function App() {

    useEffect(  () => {
        document.body.style.backgroundImage = "linear-gradient(#F3F6F9, #DDE1E5)";
        document.body.style.backgroundAttachment = "fixed";
    }, []);

    return (
        <div className="d-flex justify-content-center">
            <div className="bg-white mt-5 content-box" style={{overflow: "hidden", borderRadius: "2rem", boxShadow: "0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f"}}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/authorize" exact={true} component={LandingPage}/>} />
                        <Route path="/success" exact={true} component={SubmitSuccessPage}/>
                        <Route path="/landing-error" exact={true} component={LandingErrorPage}/>
                        <Route path="/" exact={true} component={OnboadingPage}/>
                        <Redirect from="*" to="/authorize"/>
                    </Switch>
                </BrowserRouter>
            </div>
        </div>
    );
}


export default App;
