
export const CLIENT_CODE = 'reckon-one-v2';

export const MERCHANT_DATA_PATHS = {
    PATH_CONTACT_FIRSTNAME: 'contact.firstName',
    PATH_CONTACT_LICENSE: 'contact.licenseNumber',
    PATH_CONTACT_LICENSE_CARD_NUMBER: 'contact.licenseCardNumber',
    PATH_CONTACT_LICENSE_STATE: 'contact.licenseState',
    PATH_DATE_OF_BIRTH: 'contact.dateOfBirth',
    PATH_CONTACT_LASTNAME: 'contact.lastName',
    PATH_CONTACT_EMAIL: 'contact.email',
    PATH_CONTACT_PHONE_COUNTRY_CODE: 'contact.phone.countryCode',
    PATH_CONTACT_PHONE_AREA_CODE: 'contact.phone.areaCode',
    PATH_CONTACT_PHONE_NUMBER: 'contact.phone.number',
    PATH_CONTACT_FULL_PHONE_NUMBER: 'contact.phone.number.full',
    PATH_LEGAL_ENTITY_NAME: 'legalEntity.registeredName',
    PATH_TRADING_ENTITY_NAME: 'tradingEntity.name',
    PATH_TRADING_ENTITY_ADDRESS_LINE_ONE: 'tradingEntity.addressLine1',
    PATH_TRADING_ENTITY_ADDRESS_LINE_TWO: 'tradingEntity.addressLine2',
    PATH_TRADING_ENTITY_LOCALITY: 'tradingEntity.locality',
    PATH_TRADING_ENTITY_STATE: 'tradingEntity.state',
    PATH_TRADING_ENTITY_POST_CODE: 'tradingEntity.postcode',
    PATH_PROFILE_STATEMENT_DESCRIPTOR: 'profile.statementDescriptor',
    PATH_PROFILE_ABN: 'profile.abn',
    PATH_PROFILE_ACN: 'profile.acn',
    PATH_PROFILE_MCC: 'profile.mcc',
    PATH_PROFILE_EXTERNAL_MERCHANT_ID: 'profile.externalMerchantId',
    PATH_PROFILE_INDUSTRY: 'profile.industry',
    PATH_PROFILE_CATEGORY: 'profile.category',
    PATH_PROFILE_BUSINESS_TYPE: 'profile.businessType',
    PATH_BANK_ACCOUNT_NAME: 'financials.settlement.accountName',
    PATH_BANK_ACCOUNT_BSB: 'financials.settlement.bsb',
    PATH_BANK_ACCOUNT_NUMBER: 'financials.settlement.accountNo',
}

export const ONBOARDING_PAGE_SECTION_INDEXES  = {
    CONTACT_AND_GENERAL_DETAILS_SECTION_INDEX:  0,
    BUSINESS_AND_BANK_DETAILS_SECTION_INDEX:  1,
}

export const IGNORE_EMPTY_VALIDATION_FIELD_PATHS = [
    // MERCHANT_DATA_PATHS.PATH_PROFILE_ABN,
    MERCHANT_DATA_PATHS.PATH_PROFILE_ACN,
    MERCHANT_DATA_PATHS.PATH_PROFILE_INDUSTRY,
    MERCHANT_DATA_PATHS.PATH_PROFILE_CATEGORY,
    MERCHANT_DATA_PATHS.PATH_PROFILE_BUSINESS_TYPE,
    MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_TWO,
    // MERCHANT_DATA_PATHS.PATH_CONTACT_LASTNAME,
    MERCHANT_DATA_PATHS.PATH_PROFILE_CATEGORY,
    MERCHANT_DATA_PATHS.PATH_PROFILE_EXTERNAL_MERCHANT_ID,
    MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_NUMBER
    // MERCHANT_DATA_PATHS.PATH_DATE_OF_BIRTH,
    // MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE,
];

export const ONBOARDING_FIELD_VALIDATION_MESSAGES = {
    REQUIRED_FIELD: 'Required Field',
    INVALID_PHONE_NUMBER: 'Invalid Phone Number',
    INVALID_EMAIL_ADDRESS: 'Invalid Email Address',
    INVALID_ABN: 'Invalid ABN',
    INVALID_ACN: 'Invalid ACN',
    INVALID_BSB: 'Invalid BSB',
    INVALID_ACCOUNT_NUMBER: 'Invalid Account Number',
    EMPTY_ABN_AND_EMPTY_ACN: 'ABN and ACN both cannot be empty',
}