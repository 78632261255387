import {
    IGNORE_EMPTY_VALIDATION_FIELD_PATHS,
    MERCHANT_DATA_PATHS, ONBOARDING_FIELD_VALIDATION_MESSAGES,
    ONBOARDING_PAGE_SECTION_INDEXES
} from "../../../constants/CommonConstants";
import {get, includes, isEmpty, values, concat, keys, forEach, update} from "lodash";
import {splitName} from "../../../util/CommonFunctions";

const VALID_EMAIL_REGEX_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const VALID_ABN_PATTERN = /^\d{11}$/;
const VALID_ACN_PATTERN = /^\d{9}$/;
const VALID_BSB_PATTERN = /^\d{6}$/;
const VALID_BANK_ACCOUNT_NUMBER_PATTERN = /^\d{4}\d+$/;
const VALID_PHONE_REGEX_PATTERN = /^(0\d{9})$/;

export const getInputValues = (merchantDetails) => {

    const fullPhoneNumber = concat(
        get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_AREA_CODE, ''),
        get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_NUMBER, '')
    ).join('');

    return {
        [ONBOARDING_PAGE_SECTION_INDEXES.CONTACT_AND_GENERAL_DETAILS_SECTION_INDEX]: {
            [MERCHANT_DATA_PATHS.PATH_CONTACT_FIRSTNAME]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_FIRSTNAME, ''),
                validationMessage: '',
                // isCustom: true,
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_FIRSTNAME)
            },
            [MERCHANT_DATA_PATHS.PATH_PROFILE_EXTERNAL_MERCHANT_ID]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_PROFILE_EXTERNAL_MERCHANT_ID, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_PROFILE_EXTERNAL_MERCHANT_ID)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_LASTNAME]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_LASTNAME, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_LASTNAME)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_EMAIL]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_EMAIL, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_EMAIL)
            },
            [MERCHANT_DATA_PATHS.PATH_DATE_OF_BIRTH]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_DATE_OF_BIRTH, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_DATE_OF_BIRTH)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_STATE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_STATE)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_CARD_NUMBER]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_LICENSE_CARD_NUMBER)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_COUNTRY_CODE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_COUNTRY_CODE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_COUNTRY_CODE)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_AREA_CODE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_AREA_CODE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_AREA_CODE)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_NUMBER]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_NUMBER, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_NUMBER)
            },
            [MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER]: {
                value: fullPhoneNumber,
                validationMessage: getValidationErrorMessage(MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER, fullPhoneNumber),
                isCustom: true,
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER)
            },
            [MERCHANT_DATA_PATHS.PATH_LEGAL_ENTITY_NAME]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_LEGAL_ENTITY_NAME, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_LEGAL_ENTITY_NAME)
            },
            [MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_NAME]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_NAME, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_NAME)
            },
            [MERCHANT_DATA_PATHS.PATH_PROFILE_ABN]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_PROFILE_ABN, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_PROFILE_ABN)
            },
            [MERCHANT_DATA_PATHS.PATH_PROFILE_ACN]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_PROFILE_ACN, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_PROFILE_ACN)
            },
            [MERCHANT_DATA_PATHS.PATH_PROFILE_INDUSTRY]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_PROFILE_INDUSTRY, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_PROFILE_INDUSTRY)
            },
            [MERCHANT_DATA_PATHS.PATH_PROFILE_CATEGORY]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_PROFILE_CATEGORY, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_PROFILE_CATEGORY)
            },
            [MERCHANT_DATA_PATHS.PATH_PROFILE_BUSINESS_TYPE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_PROFILE_BUSINESS_TYPE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_PROFILE_BUSINESS_TYPE)
            }
        },
        [ONBOARDING_PAGE_SECTION_INDEXES.BUSINESS_AND_BANK_DETAILS_SECTION_INDEX]: {
            [MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_ONE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_ONE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_ONE)
            },
            [MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_TWO]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_TWO, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_TWO)
            },
            [MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_LOCALITY]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_LOCALITY, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_LOCALITY)
            },
            [MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE)
            },
            [MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_POST_CODE]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_POST_CODE, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_POST_CODE)
            },
            [MERCHANT_DATA_PATHS.PATH_PROFILE_STATEMENT_DESCRIPTOR]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_PROFILE_STATEMENT_DESCRIPTOR, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_PROFILE_STATEMENT_DESCRIPTOR)
            },
            [MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NAME]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NAME, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NAME)
            },
            [MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_BSB]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_BSB, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_BSB)
            },
            [MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NUMBER]: {
                value: get(merchantDetails, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NUMBER, ''),
                validationMessage: '',
                isRequired: !includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NUMBER)
            },
        }
    }
}

export const updateMerchantDetailsBasedOnFiledValues = (merchantDetails, fieldValues) => {

    let updatedMerchantDetails = merchantDetails
    forEach(values(ONBOARDING_PAGE_SECTION_INDEXES), (section) => {
        forEach(keys(fieldValues[section]), (fieldPath) => {
            if (fieldValues[section][fieldPath].isCustom) {
                return;
            }
            updatedMerchantDetails = update(updatedMerchantDetails, fieldPath, () => fieldValues[section][fieldPath].value);
        })
    })
    return updatedMerchantDetails;

}

export const handleCustomInputChange = (section, path, formattedValue, onInputChange) => {
    if (MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER === path) {
        const areaCode = formattedValue.slice(0, 2);
        const number = formattedValue.slice(2,10)
        onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_AREA_CODE, areaCode);
        onInputChange(section, MERCHANT_DATA_PATHS.PATH_CONTACT_PHONE_NUMBER, number);
    }
}

export const getFormattedValueForInput = (path, value) => {
    if (MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER === path) {
        return getTruncateString(value, 10)
    }

    if (MERCHANT_DATA_PATHS.PATH_LEGAL_ENTITY_NAME === path) {
        return removeSpecialCharacters(getTruncateString(value, 120))
    }

    if (MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_NAME === path) {
        return removeSpecialCharacters(getTruncateString(value, 30))
    }

    if (MERCHANT_DATA_PATHS.PATH_CONTACT_FIRSTNAME === path || MERCHANT_DATA_PATHS.PATH_CONTACT_LASTNAME === path) {
        return removeNumbersAndSpecialCharacters(getTruncateString(value, 30))
    }

    if (MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NAME === path) {
        return removeNumbersAndSpecialCharacters(getTruncateString(value, 40))
    }

    if (MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_ONE === path || MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_TWO === path) {
        return removeSpecialCharacters(getTruncateString(value, 35))
    }

    if (MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_LOCALITY === path) {
        return removeNumbersAndSpecialCharacters(getTruncateString(value, 35))
    }

    if (MERCHANT_DATA_PATHS.PATH_CONTACT_EMAIL === path) {
        return getTruncateString(value, 60)
    }
    return value;
}

export const getValidationErrorMessage = (path, value) => {

    if (!includes(IGNORE_EMPTY_VALIDATION_FIELD_PATHS, path)) {
        if (isEmpty(value)) {
            return ONBOARDING_FIELD_VALIDATION_MESSAGES.REQUIRED_FIELD
        }
    }

    if (MERCHANT_DATA_PATHS.PATH_CONTACT_FULL_PHONE_NUMBER === path) {
        if (value && !value.match(VALID_PHONE_REGEX_PATTERN)){
            return ONBOARDING_FIELD_VALIDATION_MESSAGES.INVALID_PHONE_NUMBER
        }
    }

    if (MERCHANT_DATA_PATHS.PATH_CONTACT_EMAIL === path) {
        if (value && !value.match(VALID_EMAIL_REGEX_PATTERN)){
            return ONBOARDING_FIELD_VALIDATION_MESSAGES.INVALID_EMAIL_ADDRESS
        }
    }

    if (MERCHANT_DATA_PATHS.PATH_PROFILE_ABN === path) {
        if (value && !value.match(VALID_ABN_PATTERN)){
            return ONBOARDING_FIELD_VALIDATION_MESSAGES.INVALID_ABN
        }
    }

    if (MERCHANT_DATA_PATHS.PATH_PROFILE_ACN === path) {
        if (value && !value.match(VALID_ACN_PATTERN)){
            return ONBOARDING_FIELD_VALIDATION_MESSAGES.INVALID_ACN
        }
    }

    if (MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_BSB === path) {
        if (value && !value.match(VALID_BSB_PATTERN)){
            return ONBOARDING_FIELD_VALIDATION_MESSAGES.INVALID_BSB
        }
    }

    if (MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NUMBER === path) {
        if (value && !value.match(VALID_BANK_ACCOUNT_NUMBER_PATTERN)){
            return ONBOARDING_FIELD_VALIDATION_MESSAGES.INVALID_ACCOUNT_NUMBER
        }
    }

    return '';
}

export const removeSpecialCharacters = (input) => {
    return input.replace(/[^A-Za-z0-9\s]/g, "");
}

export const removeNumbersAndSpecialCharacters = (input) => {
    return input.replace(/[^A-Za-z\s]/g, "");
}

export const getTruncateString = (inputString, maxLength) => {
    if (inputString.length <= maxLength) {
        return inputString;
    } else {
        return inputString.slice(0, maxLength);
    }
}

function isBothEmpty(abnValue, acnValue) {
    return isEmpty(abnValue) && isEmpty(acnValue);
}

export const containsValidationErrors = (section, fieldValues, setFieldValues) => {

    const currentSectionFieldValues = fieldValues[section];

    const nonEmptyValidationMessages =  values(currentSectionFieldValues)
        .map(fieldValue => fieldValue.validationMessage)
        .filter(validationMessage => validationMessage === ONBOARDING_FIELD_VALIDATION_MESSAGES.REQUIRED_FIELD);

    if (!isEmpty(nonEmptyValidationMessages)) {
        return true;
    }

    const paths = keys(currentSectionFieldValues);

    let containsValidationErrors = false;
    forEach(paths, path => {
       const valueForPath = currentSectionFieldValues[path].value;
       const validationErrorMessage = getValidationErrorMessage(path, valueForPath);
       if (!isEmpty(validationErrorMessage)) {
           containsValidationErrors = true;
       }
        fieldValues[section][path].validationMessage = validationErrorMessage;
    });

/*    const abnValue = fieldValues[section][MERCHANT_DATA_PATHS.PATH_PROFILE_ABN].value;
    const acnValue = fieldValues[section][MERCHANT_DATA_PATHS.PATH_PROFILE_ABN].value;
    if (isBothEmpty(abnValue, acnValue)) {
        fieldValues[section][MERCHANT_DATA_PATHS.PATH_PROFILE_ABN].validationMessage = ONBOARDING_FIELD_VALIDATION_MESSAGES.EMPTY_ABN_AND_EMPTY_ACN;
        fieldValues[section][MERCHANT_DATA_PATHS.PATH_PROFILE_ACN].validationMessage = ONBOARDING_FIELD_VALIDATION_MESSAGES.EMPTY_ABN_AND_EMPTY_ACN;
        containsValidationErrors = true;
    }*/

    if (containsValidationErrors) {
        setFieldValues({...fieldValues})
    }

    return containsValidationErrors;
}