import {fetchAuthorizationUrl} from "../api/AuthorizeUrlGenerator";
import React, {useState} from "react";
import reckonPayments from "../images/reckon-logo.png";
import connect from "../images/Connect-to-reckon-button.png";
import Loader from "./Loader";

export function LandingPage({...props}) {

    const [authUrl, setAuthUrl] = useState("");

    let queryParams = props.location.search.substr(1);

    const [isLoading, setIsLoading] = useState(true);



    loadingAuthUrl(setAuthUrl, setIsLoading, queryParams)

    if (isLoading) {
       return <Loader/>
    }

    return (
        <div className="App">
            <div className="d-flex justify-content-center mt-4">
                <p style={{fontSize: "1.1rem", fontWeight: "600", marginBottom: "0"}}>WELCOME TO</p>
            </div>
            <div className='d-flex justify-content-center mb-5'>
                <img src={reckonPayments} className="novatti-logo"/>
            </div>

            <div className="d-flex justify-content-center mb-5 flex-column">
                <p style={{color: '#636b72', padding: "0 1rem"}}>
                    Reckon Payments, powered by Novatti enables your business to accept secure online payments directly from your invoices.
                </p>
                <a style={{color: '#00BD9D', padding: "0 1rem"}} href={process.env.REACT_APP_LEARN_MORE_URL}>
                      Learn More >
                </a>
            </div>

            <div className='d-flex justify-content-center mb-5'>
                <img src={connect} onClick={()=>raiseInvoiceClicked(authUrl)}
                     style={authUrl==="" ? {filter: "grayscale(100%)"} : {cursor: "pointer"}}/>
            </div>
            <div className="d-flex justify-content-center" style={{backgroundColor: "#EEF0F0", padding: "0 1rem"}}>
                <p className="mt-3" style={{color: '#636b72'}}>
                    By Signing up you agree to <a target="_blank" style={{color: '#00BD9D'}} href={process.env.REACT_APP_TERMS_URL}>Novatti terms</a> and <a target="_blank" style={{color: '#00BD9D'}} href={process.env.REACT_APP_PRIVACY_POLICY_URL}>privacy policy</a>
                </p>
            </div>
        </div>
    );

}


function loadingAuthUrl(setAuthUrl, setIsLoading, queryParams) {

    getAuthUrl(setIsLoading, queryParams).then(value => setAuthUrl(value));
}

async function getAuthUrl(setIsLoading, queryParams) {

    const res = await fetchAuthorizationUrl(queryParams);
    let url = res.data.data.url;
    console.log("URL "+res.data.data.url)
    if (url !== "") {
        setIsLoading(false);
    }
    return url;
}

function raiseInvoiceClicked(authUrl) {
    if (authUrl !== "") {
        window.open(authUrl, "_self")
    }
}