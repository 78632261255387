import reckonPayments from "../images/reckon-logo.png";
import closeBtnImg from "../images/close-btn.png";

export function LandingErrorPage(props) {

    const params = props.location.search.substr(1);
    return (


        <div className="App">
            <div className="d-flex justify-content-center mt-4">
                <p style={{fontSize: "1.1rem", fontWeight: "600", marginBottom: "0"}}>WELCOME TO</p>
            </div>
            <div className='d-flex justify-content-center mb-5'>
                <img src={reckonPayments} className="novatti-logo"/>
            </div>

            <div className="d-flex justify-content-center mb-3">
                {params.includes('error_code=400') && <p style={{color: '#636b72', padding: "0 1rem"}}>Failed confirm form submission.<br></br> Please try again later or contact Novatti Payments support via support@novattipayments.com</p>}
                {!params.includes('error_code') && <p style={{color: '#636b72', padding: "0 1rem"}}>Failed to retrieve details from Reckon to sign up to Novatti Payments.<br></br> Please try again later or contact Novatti Payments support via support@novattipayments.com</p> }
            </div>


            <div className='d-flex justify-content-center mb-3'>
                <img src={closeBtnImg} onClick={() => closeTabClicked()} style={{cursor: "pointer"}}/>
            </div>


        </div>
    );

}

function closeTabClicked() {
    const openedWindow = window.open("about:blank", "_self");
    openedWindow.close();

}