import axios from "axios";

export function getMerchantAuthorizedByAuthCode(queryString) {
    return axios.post(process.env.REACT_APP_ON_BOARDING_SERVICE_URL+`/v1/merchant/onboarding-auth-code`,
        {
            "params": queryString,
            "clientCode": "reckon-one-v2"
        }
    );
}

export function createOnBoardRequest(profile, accessToken) {
    return axios.post(process.env.REACT_APP_ON_BOARDING_SERVICE_URL+`/v1/merchant/onboarding-request`,profile,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
    );
}

export function submitMerchantToOnboard(merchantDetails, accessToken) {
    return axios.post(process.env.REACT_APP_ON_BOARDING_SERVICE_URL+`/v1/merchant/onboard`, merchantDetails,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }
    );
}