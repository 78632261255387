import React, {useEffect, useState} from "react";
import { FilePond, File, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import 'filepond/dist/filepond.min.css'
import './file-upload-styles.css'
import {getUploadUrl, uploadFile} from "../../../api/FileUploadClient";
import {isEmpty} from "lodash";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export const BankStatementUploader = ({ accessToken, bookId, setStatementUploaded }) => {
    const [files, setFiles] = useState([])

    useEffect(() => {
        if (isEmpty(files)) {
            setStatementUploaded(false);
        }
    }, [files]);

    const processFile = async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

        const response = await getUploadUrl(accessToken, bookId)
            .catch(() => error('Upload failed!'));
        const uploadUrl = response.data.data.uploadUrl

        const onUploadProgress = (progressEvent) => {
            progress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total);
        };

        const uploadController = new AbortController();
        uploadFile(uploadUrl, file, onUploadProgress, uploadController)
            .then(() => {
                load(file.name);
                setStatementUploaded(true);
            })
            .catch(() => error('Upload failed!'));

        return {
            abort: () => {
                uploadController.abort();
                setStatementUploaded(false);
                abort();
            },
        };
    }

    return (
        <div className='mb-2'>
            <p className='mb-1 col-form-label' style={{fontSize: "1rem"}}>Most Recent Bank Statement</p>
            <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                acceptedFileTypes={["application/pdf"]}
                name="files"
                server={{
                    process: processFile,
                }}
                allowFileSizeValidation={true}
                maxFileSize={'5MB'}
                labelIdle='Drag file to upload or</br></br><span class="filepond--label-action upload-button">Choose File</span>'
            />
        </div>
    );

}