import React from 'react';
import {FormGroup, Grid } from "@material-ui/core";
import {OnboardingInputField} from "../components/OnboardingInputField";
import {MERCHANT_DATA_PATHS, ONBOARDING_PAGE_SECTION_INDEXES} from "../../../constants/CommonConstants";
import {BankStatementUploader} from "../components/BankStatementUploader";
import {OnboardingDropDownInputField} from "../components/OnboardingDropDownInputField";

export const BusinessAndBankDetailsSection = ({onInputChange, inputValues, setStatementUploaded, ...props}) => {

    const section = ONBOARDING_PAGE_SECTION_INDEXES.BUSINESS_AND_BANK_DETAILS_SECTION_INDEX;

    return (
        <React.Fragment>
            <Grid item md={6} xs={12} className='pl-4 pr-4'>
                <div className="d-flex mt-4 mb-4" >
                    <p style={{fontSize: "1.4rem", marginBottom: "0"}}>Business Address</p>
                </div>
                <div className="form-box-wrapper">
                    <FormGroup>
                        <OnboardingInputField
                            label="Address Line 1"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_ONE]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_ONE, value)
                            }
                        />
                        <OnboardingInputField
                            label="Address Line 2"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_TWO]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_ADDRESS_LINE_TWO, value)
                            }
                        />
                        <OnboardingInputField
                            label="Suburb"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_LOCALITY]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_LOCALITY, value)
                            }
                        />
                        {/*<OnboardingInputField*/}
                        {/*    label="State"*/}
                        {/*    {...inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE]}*/}
                        {/*    onInputChangeHandler={*/}
                        {/*        value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE, value)*/}
                        {/*    }*/}
                        {/*/>*/}
                        <OnboardingDropDownInputField
                            label="State"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE, value)
                            }
                            defaultValue={inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_STATE]}
                            menuItemValues={[
                                {key: 'ACT', value: 'Australian Capital Territory'},
                                {key: 'NSW', value: 'New South Wales'},
                                {key: 'NT', value: 'Northern Territory'},
                                {key: 'QLD', value: 'Queensland'},
                                {key: 'SA', value: 'South Australia'},
                                {key: 'TAS', value: 'Tasmania'},
                                {key: 'VIC', value: 'Victoria'},
                                {key: 'WA', value: 'Western Australia'}

                            ]}
                        />
                        <OnboardingInputField
                            label="Postcode"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_POST_CODE]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_TRADING_ENTITY_POST_CODE, value)
                            }
                        />
                    </FormGroup>
                </div>
            </Grid>
            <Grid item md={6} xs={12} className='desktop-only-left-border pl-4 pr-4'>
                <div className="d-flex mt-4 mb-4">
                    <p style={{fontSize: "1.4rem", marginBottom: "0"}}>Bank Account Details</p>
                </div>
                <div className="form-box-wrapper">
                    <FormGroup>
                        <OnboardingInputField
                            label="Billing Descriptor"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_PROFILE_STATEMENT_DESCRIPTOR]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_PROFILE_STATEMENT_DESCRIPTOR, value)
                            }
                        />
                        <OnboardingInputField
                            label="Account Name"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NAME]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NAME, value)
                            }
                        />
                        <OnboardingInputField
                            label="BSB"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_BSB]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_BSB, value)
                            }
                        />
                        <OnboardingInputField
                            label="Account Number"
                            {...inputValues[MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NUMBER]}
                            onInputChangeHandler={
                                value => onInputChange(section, MERCHANT_DATA_PATHS.PATH_BANK_ACCOUNT_NUMBER, value)
                            }
                        />
                        {/*<BankStatementUploader {...props} setStatementUploaded={setStatementUploaded}/>*/}
                    </FormGroup>
                </div>
            </Grid>
        </React.Fragment>
    );
}