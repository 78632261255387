import novattiBrandIcon from "../images/reckon-logo.png";
import closeBtnImg from "../images/close-btn.png";
import connect from "../images/Connect-to-reckon-button.png";

export function SubmitSuccessPage() {

    return (

        <div className="App">
            <div className='d-flex justify-content-center mt-4 mb-5'>
                <img src={novattiBrandIcon} className="novatti-logo"/>
            </div>

            <div className="d-flex justify-content-center mb-3">
                <p style={{color: '#636b72', padding: "0 1rem"}}>Your onboarding request has been successfully received and it will be processed in the next 48 hours.</p>
            </div>

            <div className='d-flex justify-content-center mb-3'>
                <img src={closeBtnImg} onClick={() => closeTabClicked()} style={{cursor: "pointer"}}
                />

            </div>

        </div>
    );

}

function closeTabClicked() {
    const openedWindow = window.open("about:blank", "_self");
    openedWindow.close();

}